import { useStyles, Subheader, pxToRem } from '@archipro-design/aria';

import * as S from './HeaderSection.style';

import { Container } from '@modules/professional/component/page-layout/PageLayout.style';

import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { BannerAndLogoMobile } from '../overview-tab/banner-and-logo/BannerAndLogo.mobile';
import SubRouteNavigationMobile from '../sub-route-navigation/SubRouteNavigation.mobile';
import type { Breadcrumb } from '@modules/professional/type/details-root.types';
import { Link, useLoaderData } from '@remix-run/react';
import {
    locationHasDistance,
    useLocationsSortByDistance,
} from '../../hook/use-locations-sort-by-distance';
import type { LocationDataType } from '../../type';
import {
    convertLocationData,
    getLocationSuburbText,
} from '../../util/contact-page-helper';
import { useMatchesHandleData } from '~/modules/root';
import type { ProfessionalDetailRootLoaderData } from '../../page/ProfessionalPage';
import { superscriptize } from '~/utils';
import type { ProfessionalBannersData } from '@modules/professional/page/OverviewPage';
import type { AwardProps } from '@modules/page-builder/component/detail/banner-awards/types';

interface HeaderSectionProps extends React.PropsWithChildren {
    professionalId: number;
    banners?: ProfessionalBannersData;
    awards?: AwardProps[];
    breadcrumbs: Breadcrumb[] | undefined;
    companyLogo: string | undefined;
    logoBackgroundColor: string | undefined;
}

export const HeaderSectionMobile = ({
    professionalId,
    banners = [],
    awards,
    companyLogo = '',
    logoBackgroundColor = 'white',
    breadcrumbs = [],
    children,
}: HeaderSectionProps) => {
    const { css } = useStyles();
    const { Title, LibraryLocations, Country } =
        useProfessionalInitialRouteData();
    const isBMPreview = useMatchesHandleData('isBMPreview', false);
    const { isApStaff, country, linkedProfiles } =
        useLoaderData<ProfessionalDetailRootLoaderData>();
    const iconAdjustedTitle = superscriptize(
        Title + (isApStaff && country !== Country ? ` (${Country})` : '')
    );
    const linkedLocations =
        linkedProfiles?.linkedProfilesList.flatMap((p) =>
            p.LibraryLocations.filter((loc) => !loc.LocationHidden)
        ) ?? [];

    const { sortedLocations } = useLocationsSortByDistance<LocationDataType>({
        locations: [...LibraryLocations, ...linkedLocations].map(
            convertLocationData
        ),
        skip: true,
    });
    const hasDistance = locationHasDistance(sortedLocations);
    const showLocation =
        !isBMPreview && (hasDistance || sortedLocations.length === 1);

    return (
        <div className={css(Container)}>
            <BannerAndLogoMobile
                title={Title}
                professionalID={professionalId}
                banners={banners}
                awards={awards}
                companyLogoURI={companyLogo}
                logoBackgroundColor={logoBackgroundColor}
            />
            <div className={css(S.MobileTitleSection)}>
                <Subheader
                    variant={'03'}
                    className={css(S.Heading)}
                    dangerouslySetInnerHTML={{
                        __html: iconAdjustedTitle,
                    }}
                    variables={{
                        fontSize: pxToRem(24),
                        lineHeight: pxToRem(24),
                        fontWeight: 500,
                    }}
                />
                <div className={css(S.SubHeadingMobile)}>
                    {breadcrumbs?.[breadcrumbs.length - 1]?.Title ?? ''}
                    {showLocation && !isBMPreview && (
                        <>
                            &nbsp;&#x2022;&nbsp;
                            <Link
                                to={`contact`}
                                state={{ ID: sortedLocations[0]?.ID }}
                                prefetch="intent"
                            >
                                {getLocationSuburbText(sortedLocations[0])}
                                {sortedLocations.length > 1 &&
                                    ` (+${sortedLocations.length - 1})`}
                            </Link>
                        </>
                    )}
                </div>
                {children}
            </div>
            <SubRouteNavigationMobile />
        </div>
    );
};
