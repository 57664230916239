import {
    useStyles,
    Button,
    pxToRem,
    Flex,
    useTheme,
} from '@archipro-design/aria';
import {
    ExternalLinkLine,
    HeartLine,
    HeartFill,
    InboxCustom,
} from '@archipro-design/icons';
import EnquiryModal from '~/modules/enquiry/component/enquiry-modal/EnquiryModal';
import * as S from './HeaderSection.style';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { useAppData, useMatchesHandleData } from '@modules/root';
import { useTracker } from '@archipro-website/tracker';
import { useWebsiteReferralTracking } from '~/modules/tracking/util/websiteTracking';
import { useIsomorphicLayoutEffect, useSize } from 'ahooks';
import { useRef } from 'react';
import { trackEnquireReveal } from '~/modules/tracking/util/trackEnquireReveal';
import SaveToDesignBoard from '~/modules/design-board/component/SaveToDesignBoard';
import { useSaveToDesignBoard } from '~/modules/design-board';
import { useSearchParams } from '@remix-run/react';
import type { HeaderSectionProps } from './HeaderSection';
import BookProfessional from '@modules/professional/component/header-section/BookProfessional';

import cx from 'classnames';
import { PrimaryCtaEnum } from '../../../../../generated/graphql';

const RightHeaderActionLinkDesktop = ({
    primaryCTA,
    isEnquiryOpen,
    onEnquiryModalOpenChange,
}: Pick<HeaderSectionProps, 'primaryCTA'> & {
    isEnquiryOpen: boolean;
    onEnquiryModalOpenChange: (isOpen: boolean) => void;
}) => {
    const professional = useProfessionalInitialRouteData();
    const { branchOptions, ID, Title, Website, TeamCalendarURLSegment } =
        professional;
    const isBMPreview = useMatchesHandleData('isBMPreview', false);
    const [searchParams] = useSearchParams();
    const tracker = useTracker();

    const {
        referralTrackingURL,
        referralTrackingClass,
        referralTrackingParams,
    } = useWebsiteReferralTracking(Website, {
        libraryItemID: professional.ID,
        professionalTitle: professional.Title,
    });

    const innerRef = useRef<HTMLDivElement>(null);
    const innerSize = useSize(innerRef);

    const { css } = useStyles({ innerHeight: innerSize?.height, isBMPreview });
    const { favouriteItem } = useSaveToDesignBoard('ProfessionalPin', ID);

    useIsomorphicLayoutEffect(() => {
        if (!isBMPreview && searchParams.get('enquiry')) {
            onEnquiryModalOpenChange(true);
        }
    }, [searchParams, isBMPreview]);

    const theme = useTheme();

    const { bookConsultationEnabled } = useAppData();
    const showBookConsultation =
        TeamCalendarURLSegment &&
        bookConsultationEnabled &&
        professional.primaryCTA?.primaryCTA === PrimaryCtaEnum.BookConsultation;

    return (
        <>
            {referralTrackingURL ? (
                <Button
                    as="a"
                    href={referralTrackingURL}
                    disabled={isBMPreview}
                    target="_blank"
                    size={16}
                    color="dark"
                    variant="outlined"
                    className={cx(css(S.HeaderButton), referralTrackingClass)}
                    data-testid="website-link"
                    iconAfter={<ExternalLinkLine />}
                    variables={{
                        iconPadding: 0,
                        buttonSize16IconXMargin: pxToRem(10),
                        darkOutlinedButtonBorderNormal: `1px solid ${theme.siteVariables.colors.charcoal['250']}`,
                        darkOutlinedButtonBorderHover: `1px solid ${theme.siteVariables.colors.charcoal['250']}`,
                        darkOutlinedButtonBorderDisable: `1px solid ${theme.siteVariables.colors.charcoal['250']}`,
                    }}
                    {...referralTrackingParams}
                >
                    visit website
                </Button>
            ) : null}
            <Flex className={css(S.EnquiryAndSaveWrapper)}>
                {showBookConsultation ? (
                    <BookProfessional
                        isEnquiryOpen={isEnquiryOpen}
                        onEnquiryModalOpenChange={onEnquiryModalOpenChange}
                        professional={professional}
                        enquiryType={'professional'}
                    />
                ) : (
                    <>
                        <EnquiryModal
                            open={isEnquiryOpen}
                            onOpenChange={onEnquiryModalOpenChange}
                            professional={professional}
                            branchOptions={branchOptions}
                            trigger={
                                <Button
                                    color="dark"
                                    disabled={isBMPreview}
                                    className={css(S.HeaderButton)}
                                    size={16}
                                    onClick={() => {
                                        trackEnquireReveal(tracker, {
                                            professionalName: Title,
                                            itemID: ID,
                                            itemType: 'professional',
                                            source: 'EnquiryModal',
                                        });
                                    }}
                                    variables={{
                                        iconPadding: 0,
                                        buttonSize16IconXMargin: pxToRem(10),
                                        darkButtonBorderNormal: 'none',
                                    }}
                                    iconAfter={<InboxCustom />}
                                >
                                    {primaryCTA.primaryCTALabel}
                                </Button>
                            }
                        />
                        <SaveToDesignBoard
                            {...favouriteItem}
                            disable={isBMPreview}
                        >
                            <Button
                                variant="outlined"
                                disabled={isBMPreview}
                                className={cx(
                                    css(S.SquareIconButton),
                                    css(S.DesignBoardButton)
                                )}
                                size={16}
                                icon={
                                    favouriteItem.isFavourited ? (
                                        <HeartFill size="1x" />
                                    ) : (
                                        <HeartLine size="1x" />
                                    )
                                }
                            />
                        </SaveToDesignBoard>
                    </>
                )}
            </Flex>
        </>
    );
};

export default RightHeaderActionLinkDesktop;
