import { Header, useStyles, Caption } from '@archipro-design/aria';
import * as S from './HeaderSection.style';
import SubRouteNavigation from '../sub-route-navigation/SubRouteNavigation';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { useMatchesHandleData } from '@modules/root';
import { useRef, useState } from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import type { ProfessionalDetailRootLoaderData } from '../../page/ProfessionalPage';
import { superscriptize } from '~/utils';
import { NavItem } from '../sub-route-navigation/SubRouteNavigation.style';
import type { HeaderSectionProps } from './HeaderSection';
import RightHeaderActionLinkDesktop from './RightHeaderActionLink.desktop';
import { useScroll, useSize } from 'ahooks';

export const HeaderSectionDesktop = ({
    breadcrumbs = [],
    primaryCTA,
}: HeaderSectionProps) => {
    const { isApStaff, country, linkedProfiles } =
        useLoaderData<ProfessionalDetailRootLoaderData>();
    const {
        Title,
        Link: professionalLink,
        LibraryLocations,
        Country,
    } = useProfessionalInitialRouteData();
    const isBMPreview = useMatchesHandleData('isBMPreview', false);
    const hasLinkedLocation =
        linkedProfiles?.linkedProfilesList.some((p) =>
            p.LibraryLocations.some((l) => !l.LocationHidden)
        ) ?? false;

    const iconAdjustedTitle =
        superscriptize(Title) +
        (isApStaff && country !== Country ? ` (${Country})` : '');

    const containerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const innerSize = useSize(innerRef);

    const position = useScroll();

    const { css } = useStyles({
        innerHeight: innerSize?.height,
        scrollY: position?.top,
        isBMPreview,
    });
    const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);

    const professionalPageWithEnquiry =
        professionalLink +
        (professionalLink.includes('?') ? '&' : '?') +
        'enquiry=1';

    const handleEnquiryModalOpenChange = (isOpen: boolean) => {
        setEnquiryModalOpen(isOpen);
    };

    const lastBreadcrumb = breadcrumbs?.[breadcrumbs.length - 1];
    const professionalCategoryLabel = lastBreadcrumb
        ? `${lastBreadcrumb?.Title}`
        : '';

    const professionalCategory = isBMPreview ? (
        <span>{professionalCategoryLabel}</span>
    ) : (
        <Link to={breadcrumbs?.[breadcrumbs.length - 1]?.Link ?? ''}>
            {professionalCategoryLabel}
        </Link>
    );

    return (
        <div className={css(S.ProfessionalHeaderFixedWrapper)}>
            <div
                ref={containerRef}
                className={css(S.ProfessionalHeaderContainer)}
            >
                <div className={css(S.PageHeader)}>
                    <div ref={innerRef} className={css(S.PageHeaderInner)}>
                        <div className={css(S.LeftPane)}>
                            <SubRouteNavigation>
                                {!LibraryLocations.some(
                                    (l) => !l.LocationHidden
                                ) &&
                                    !hasLinkedLocation && (
                                        <Link
                                            className={css(NavItem)}
                                            to={professionalPageWithEnquiry}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setEnquiryModalOpen(true);
                                            }}
                                        >
                                            <Caption variant="03">
                                                Contact
                                            </Caption>
                                        </Link>
                                    )}
                            </SubRouteNavigation>
                        </div>
                        <div className={css(S.RightPane)}>
                            <div className={css(S.RightPaneInner)}>
                                <div className={css(S.ProjectTitleContainer)}>
                                    <Header
                                        as="h1"
                                        className={css(S.ProjectTitle)}
                                        uc={true}
                                        dangerouslySetInnerHTML={{
                                            __html: iconAdjustedTitle,
                                        }}
                                    />

                                    <div className={css(S.ProfessionalTitle)}>
                                        {professionalCategory}
                                    </div>
                                </div>
                                <RightHeaderActionLinkDesktop
                                    primaryCTA={primaryCTA}
                                    isEnquiryOpen={enquiryModalOpen}
                                    onEnquiryModalOpenChange={
                                        handleEnquiryModalOpenChange
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css(S.ProfessionalHeaderSpacer)} />
        </div>
    );
};
