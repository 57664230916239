import type { ProfileNavigationItemProps } from '@archipro-design/aria';
import { ProfileNavigation, useStyles } from '@archipro-design/aria';
import { useLoaderData, useMatches } from '@remix-run/react';
import { useProfessionalInitialRouteData } from '@modules/professional/hook/use-professional-initial-route-data';
import { getSubRoutes, getProfileNavItems } from './utils';
import * as S from './SubRouteNavigation.mobile.style';
import { useCanEdit } from '~/modules/root/hook';
import React from 'react';
import { useHydrated } from 'remix-utils';
import { useIsomorphicLayoutEffect } from 'ahooks';
import { parseURL } from '@archipro-website/top-navigation';
import EnquiryModal from '~/modules/enquiry/component/enquiry-modal/EnquiryModal';
import type { ProfessionalDetailRootLoaderData } from '../../page/ProfessionalPage';

const scrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
};

const SubRouteNavigationMobile: React.FC = () => {
    const professionalInitial = useProfessionalInitialRouteData();
    const { linkedProfiles } =
        useLoaderData<ProfessionalDetailRootLoaderData>();
    const matches = useMatches();
    const currentRoute = matches[matches.length - 1]?.pathname ?? '';
    const { basePath } = parseURL(currentRoute);
    const pathList = basePath.split('/').filter(Boolean);

    const currentTabPath = `/${
        pathList.length > 2 ? pathList[pathList.length - 1] : ''
    }`;

    const {
        ID: professionalId,
        LibraryLocations,
        ...professional
    } = professionalInitial;

    const canEdit = useCanEdit(professionalId);

    const menuItems = getSubRoutes(
        professionalInitial,
        canEdit,
        linkedProfiles?.linkedProfilesList
    );

    const navItems: Array<ProfileNavigationItemProps & { key: string }> =
        getProfileNavItems(menuItems, currentTabPath);
    const currentIndex = navItems.findIndex((item) => item.selected);
    const [activeIndex, setActiveIndex] = React.useState(currentIndex);

    const navRef = React.useRef<HTMLDivElement>(null);
    const hydrated = useHydrated();

    const { css } = useStyles({
        isDarkButton: currentTabPath === menuItems[0]?.link,
    });

    const hasLocation =
        LibraryLocations.some((l) => !l.LocationHidden) ||
        linkedProfiles?.linkedProfilesList.some((p) =>
            p.LibraryLocations.some((l) => !l.LocationHidden)
        );
    if (!hasLocation) {
        navItems.push({
            key: 'contact',
            children: (
                <EnquiryModal
                    professional={{
                        ...professionalInitial,
                        LibraryLocations: [],
                    }}
                    branchOptions={professional.branchOptions}
                    drawer
                    trigger={<div>Contact</div>}
                />
            ),
            selected: false,
            as: 'a',
            onClick: (e) => {
                e.preventDefault();
            },
        });
    }

    React.useEffect(() => {
        setActiveIndex(currentIndex);
    }, [currentIndex]);

    useIsomorphicLayoutEffect(() => {
        if (hydrated) {
            const link = navRef.current?.querySelector(
                `a:nth-child(${activeIndex + 1})`
            );

            if (!link) {
                return;
            }
            link.scrollIntoView(scrollOptions);
        }
    }, [activeIndex, navRef, hydrated]);
    return (
        <ProfileNavigation
            ref={navRef}
            className={css(S.MobileNavTabWrapper)}
            items={navItems.map((item, i) => ({
                ...item,
                onClick:
                    item.onClick ||
                    ((e) => {
                        (e.target as Element).scrollIntoView(scrollOptions);
                        setActiveIndex(i);
                    }),
                selected: i === activeIndex,
            }))}
        />
    );
};

export default SubRouteNavigationMobile;
